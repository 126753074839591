import dynamic from 'next/dynamic';
// import LocationDropdown from './LocationDropdown';
const LocationDropdown = dynamic(() => import('./LocationDropdown'), {
  ssr: false,
  loading: () => <div className='font-hneu text-white'>Location...</div>,
});

const Topbar = () => {
  return (
    <div className='relative !z-[999999] flex min-h-[45px] w-full items-center justify-end'>
      <div className='flex items-center gap-2'>
        <LocationDropdown />
      </div>
    </div>
  );
};

export default Topbar;
