import { bookingApi } from '@/api-client/booking';
import TableContainer from '@/components/sections/TableContainer';
import useMobileScreen from '@/hooks/useMobileScreen';
import { IProfile } from '@/interface/auth';
import { StatusBookingKey } from '@/utils/enum';
import { imgUrlTransferV1 } from '@/utils/global-func';
import {
  AuditOutlined,
  CarryOutOutlined,
  ClockCircleOutlined,
  DollarOutlined,
  EnvironmentOutlined,
} from '@ant-design/icons';
import { useQuery } from '@tanstack/react-query';
import { Button, Collapse, Image, Modal, TableColumnsType, Tabs } from 'antd';
import clsx from 'clsx';
import { useState } from 'react';
import styled from 'styled-components';

export interface IProfileBookingProps {
  isModalOpen: boolean;
  profile: IProfile;
  setOpenModal: (v: boolean) => void;
}

const StyledTabs = styled(Tabs)`
  .ant-tabs-nav {
    margin-bottom: 0;
  }

  .ant-tabs-nav-wrap {
    padding-top: 12px !important;
  }

  .ant-tabs-nav-list {
    width: 100%;
    display: flex !important;
  }

  .ant-tabs-nav {
    margin-bottom: 0;
    transition: all 0.3s ease-in-out;
    &::before {
      border: none !important; /* Remove bottom border */
    }
  }

  .ant-tabs-content-holder {
    padding-top: 12px !important;
  }

  .ant-tabs-nav-list {
    // background-color: #f0f2f5;
    border-radius: 6px;
    gap: 4px;
  }

  .ant-tabs-tab-btn {
    width: 100%;
  }

  .ant-tabs-tab {
    margin: 0 !important;
    padding: 6px 16px;
    border-radius: 6px;
    color: #637381;
    font-weight: 500;
    transition: all 0.3s;
    flex: 1;
    justify-content: center;
    text-align: center;

    &:hover {
      color: #1d3f75;
    }

    &.ant-tabs-tab-active {
      background-color: #1d3f75;

      .ant-tabs-tab-btn {
        color: white !important;
      }
    }
  }

  .ant-tabs-ink-bar {
    display: none;
  }
`;

const CustomCollapse = styled(Collapse)`
  .ant-collapse-expand-icon > span svg {
    fill: #a7a7a7 !important;
  }
`;

const ProfileBooking = (props: IProfileBookingProps) => {
  const { isModalOpen, profile, setOpenModal } = props;
  const isMobile = useMobileScreen();

  const bookingByMemberQuery = useQuery({
    queryKey: ['booking-by-mmeber', profile._id],
    queryFn: () => bookingApi.findBookingByMember(profile._id),
    staleTime: 60,
    gcTime: 120,
    refetchOnWindowFocus: false,
    enabled: !!profile._id,
  });

  const bookingMemberData = bookingByMemberQuery.data?.data || [];

  const columns: TableColumnsType<any> = [
    {
      key: 'date',
      title: 'Date',
      dataIndex: 'date',
      width: 120,
      ellipsis: true,
      showSorterTooltip: { title: 'Sort by Date', placement: 'top' },
      sorter: (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime(), // Sắp xếp theo timestamp
      defaultSortOrder: 'ascend', // Ngày mới nhất trước
      render: date => new Date(date).toLocaleDateString(), // Định dạng ngày hiển thị
    },
    {
      key: 'service_booking',
      title: 'Service',
      dataIndex: 'service_booking',
      width: 200,
      showSorterTooltip: { target: 'full-header' },
      sorter: (a, b) => a?.service_booking.service?.name?.length - b?.service_booking.service?.name?.length,
      defaultSortOrder: 'descend',
      render: (_, record) => (
        <span className='flex items-center justify-center'>{record?.service_booking.service?.name}</span>
      ),
    },
    // {
    //   key: 'duration',
    //   title: 'Duration',
    //   dataIndex: 'duration',
    //   width: 110,
    //   showSorterTooltip: { target: 'full-header' },
    //   sorter: (a, b) => a?.duration?.length - b?.duration?.length,
    //   defaultSortOrder: 'descend',
    //   render: (_, record) => <span className='flex justify-center'>{record.duration}</span>,
    // },
    {
      key: 'amount',
      title: 'Amount',
      dataIndex: 'amount',
      width: 110,
      render: (_, record) => (
        <span className='flex justify-center font-medium text-zelene-red'>$&nbsp;{record.price}</span>
      ),
    },
    {
      key: 'storeBranch',
      title: 'Location',
      dataIndex: 'storeBranch',
      width: 110,
      render: (_, record) => <span className='flex justify-center'>{record?.storeBranch?.name}</span>,
    },
    {
      key: 'service_booking',
      title: 'Staff',
      dataIndex: 'service_booking',
      width: 200,
      showSorterTooltip: { target: 'full-header' },
      defaultSortOrder: 'descend',
      render: (_, record) => (
        <span className='flex justify-center'>
          {record?.service_booking.employee?.first_name} {record?.service_booking.employee?.last_name}
        </span>
      ),
    },
    {
      key: 'status',
      title: 'Status',
      dataIndex: 'status',
      width: 150,
      showSorterTooltip: { target: 'full-header' },
      sorter: (a, b) => a?.status?.length - b?.status?.length,
      defaultSortOrder: 'descend',
      render: (_, record) => (
        <p
          className={clsx(
            'flex justify-center rounded-xl py-1 text-white',
            record.status === StatusBookingKey.PENDING && 'bg-zelene-yellow',
            record.status === 'Unconfirmed' && 'bg-zelene-red',
            record.status === 'Confirmed' && 'bg-zelene-blue',
            record.status === StatusBookingKey.COMPLETED && 'bg-[#29751D]',
            record.status === 'Cancel' && 'bg-[#B84E44]',
          )}
        >
          {record.status}
        </p>
      ),
    },

    // {
    //   title: 'Action',
    //   dataIndex: 'action',
    //   width: 130,
    //   align: 'center',
    //   fixed: 'right',
    //   render: (_, record) => {
    //     return (
    //       <div className='flex items-center justify-center'>
    //         <Button
    //           size='middle'
    //           className='!rounded-2xl !border-zelene-red !bg-transparent !text-zelene-red'
    //           icon={<EditOutlined className='text-zelene-blue' />}
    //         >
    //           Cancel
    //         </Button>
    //       </div>
    //     );
    //   },
    // },
  ];

  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);

  const handleExpand = (expanded: boolean, record: any) => {
    setExpandedRowKeys(expanded ? [record._id] : []); // Chỉ giữ một hàng mở rộng
  };

  const handleCollapse = (key: string) => {
    setExpandedRowKeys(expandedRowKeys.filter(expandedKey => expandedKey !== key)); // Đóng hàng hiện tại
  };

  return (
    <Modal
      title={
        <div>
          <p className='mb-0 text-xl font-bold text-zelene-blue'>My Booking</p>
        </div>
      }
      open={isModalOpen}
      onClose={() => setOpenModal(false)}
      onCancel={() => setOpenModal(false)}
      footer={[]}
      width={1200}
    >
      <StyledTabs
        defaultActiveKey='2'
        items={[
          // {
          //   key: '1',
          //   label: 'Upcoming Appointment',
          //   children: (
          //     <div className='rounded-md bg-[#DBEAF8] p-4'>
          //       <div className='rounded-md bg-[#FFFFFF] px-5 py-4'>
          //         <TableContainer
          //           loading={
          //             bookingByMemberQuery.isLoading ||
          //             bookingByMemberQuery.isFetching ||
          //             bookingByMemberQuery.isRefetching
          //           }
          //           dataSource={bookingMemberData}
          //           columns={columns}
          //           rowKey={(item: any) => item?._id}
          //           scroll={{ x: 1000, y: 'calc(100vh - 500px)' }}
          //         />
          //       </div>
          //     </div>
          //   ),
          // },
          {
            key: '2',
            label: 'History',
            children: (
              <div className='rounded-md bg-[#DBEAF8] p-4'>
                <div className='rounded-md bg-[#FFFFFF] px-5 py-4'>
                  <TableContainer
                    loading={
                      bookingByMemberQuery.isLoading ||
                      bookingByMemberQuery.isFetching ||
                      bookingByMemberQuery.isRefetching
                    }
                    dataSource={bookingMemberData}
                    columns={columns}
                    rowKey={(item: any) => item?._id}
                    scroll={{ x: 1000, y: 'calc(100vh - 500px)' }}
                    expandable={
                      isMobile
                        ? {
                            expandedRowRender: (record: any) => {
                              const { duration, price, status, service_booking, storeBranch, addon_booking, date } =
                                record;

                              const addonItems = addon_booking?.map((item: any, index: number) => ({
                                key: item._id,
                                label: <span className='font-medium'>{item?.addon?.name}</span>,
                                extra: <span className='font-medium'>$&nbsp;{item?.addon?.price}</span>,
                                children: (
                                  <p className='flex flex-col justify-center gap-2'>
                                    <div className='relative aspect-square'>
                                      <Image
                                        alt='img'
                                        src={imgUrlTransferV1(service_booking?.service?.image)}
                                        width={'100%'}
                                        height={'100%'}
                                      />
                                    </div>
                                  </p>
                                ),
                              }));

                              return (
                                <div className='flex flex-col gap-2'>
                                  <div className='flex gap-2'>
                                    <AuditOutlined />
                                    <strong className='font-hneu text-[17px]'>{service_booking.service?.name}</strong>
                                  </div>
                                  <div className='relative aspect-square'>
                                    <Image
                                      alt='img'
                                      src={imgUrlTransferV1(service_booking?.service?.image)}
                                      width={'100%'}
                                      height={'100%'}
                                    />
                                  </div>
                                  <div className='flex items-center gap-1'>
                                    <EnvironmentOutlined />
                                    <strong>{storeBranch?.name}</strong>
                                  </div>
                                  <div className='flex items-center justify-between gap-1 *:flex-1'>
                                    <div className='flex items-center gap-1'>
                                      <DollarOutlined />
                                      <strong>{price}</strong>
                                    </div>

                                    <div className='flex items-center gap-1'>
                                      <ClockCircleOutlined />
                                      <strong>{duration}</strong>&nbsp;mins
                                    </div>
                                  </div>

                                  <div className='flex items-center justify-between gap-1 *:flex-1'>
                                    <div className='flex items-center gap-1'>
                                      <CarryOutOutlined />
                                      <strong>{date}</strong>
                                    </div>

                                    <span
                                      className={clsx(
                                        'flex w-fit justify-center rounded-xl px-3 py-1 text-white',
                                        record.status === StatusBookingKey.PENDING && 'bg-zelene-yellow',
                                        record.status === 'Unconfirmed' && 'bg-zelene-red',
                                        record.status === 'Confirmed' && 'bg-zelene-blue',
                                        record.status === StatusBookingKey.COMPLETED && 'bg-[#29751D]',
                                        record.status === 'Cancel' && 'bg-[#B84E44]',
                                      )}
                                    >
                                      {record.status}
                                    </span>
                                  </div>

                                  {addon_booking?.length > 0 && (
                                    <div className='flex flex-col gap-2'>
                                      <span className='font-hneu font-semibold'>Add-ons:</span>

                                      <div className='flex flex-col gap-2'>
                                        <CustomCollapse items={addonItems} defaultActiveKey={['1']} />
                                      </div>
                                    </div>
                                  )}
                                  <Button
                                    size='large'
                                    className='!mt-3 !rounded-full !border-zelene-blue !bg-zelene-blue !text-white'
                                    onClick={() => handleCollapse(record._id)}
                                  >
                                    Close
                                  </Button>
                                </div>
                              );
                            },
                            rowExpandable: record => record._id !== 'Not Expandable',
                            expandedRowKeys, // Quản lý hàng mở rộng
                            onExpand: handleExpand, // Điều khiển trạng thái mở rộng
                            fixed: 'left',
                          }
                        : undefined
                    }
                  />
                </div>
              </div>
            ),
          },
        ]}
      />
    </Modal>
  );
};

export default ProfileBooking;
