import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { Table, TableProps } from 'antd';
import { ColumnType } from 'antd/es/table';
import React from 'react';

interface ITableContainerProps extends TableProps {
  defaultPageSize?: number;
  pageSizeOptions?: string[];
}

const TableContainer = (props: ITableContainerProps) => {
  const { defaultPageSize = 10, pageSizeOptions = ['10', '20', '50', '100'], columns, ...rest } = props || {};

  // Transform columns with custom styles
  const styledColumns: any = React.useMemo(() => {
    if (!columns) return [];

    return columns.map((column: ColumnType<any>) => ({
      ...column,
      // className: `${column.className || ''} text-gray-700`,
      // Custom header cell styling
      title: <span className='font-medium text-zelene-blue'>{column?.title as any}</span>,
      // Custom header cell props
      // onHeaderCell: (column: ColumnType<any>) => ({
      //   style: {
      //     backgroundColor: 'red', // light gray background
      //     fontWeight: 500,
      //   },
      // }),
      // Custom body cell props
      onCell: (record: any, index: number) => ({
        style: {
          color: '#1d3f75',
          fontWeight: 500,
          // backgroundColor: index % 2 === 0 ? 'white' : '#E1E1E1',
        },
      }),
    }));
  }, [columns]);

  return (
    <Table
      pagination={{
        showQuickJumper: true,
        defaultPageSize: defaultPageSize,
        showSizeChanger: true,
        pageSizeOptions: pageSizeOptions,
        showPrevNextJumpers: true,
        position: ['bottomCenter'],
        itemRender: (page, type, originalElement) => {
          if (type === 'prev' || type === 'next') {
            return (
              <button className='relative inline-flex items-center rounded-md px-6 py-2 text-sm font-medium text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0'>
                {type === 'prev' ? <ArrowLeftOutlined /> : <ArrowRightOutlined />}
              </button>
            );
          }
          return originalElement;
        },

        showTotal: val => (
          <div className='hidden sm:block'>
            <span className='font-bold'>{val || '0'}</span>&nbsp;columns in total
          </div>
        ),
      }}
      columns={styledColumns}
      {...rest}
      bordered
    />
  );
};

export default TableContainer;
