'use client';

import CartList from '@/components/elements/Cart/CartList';
import { useAuth } from '@/hooks/auth.hook';
import { useCart } from '@/hooks/cart.hook';
import { useWindowScroll } from '@/hooks/useWindowScroll';
import { MenuFoldOutlined } from '@ant-design/icons';
import { Drawer } from 'antd';
import clsx from 'clsx';
import Image from 'next/image';
import Link from 'next/link';
import { usePathname, useRouter } from 'next/navigation';
import React from 'react';
import EmptyCart from '../../../../public/images/Cart/EmptyCart.webp';
import Cart from '../../../../public/images/CartImage.png';
import LogoHeader from '../../../../public/images/Common/HeaderLogo.webp';
import yellowStar from '../../../../public/images/yellowStar.png';
import { HomeAuthAction } from './auth-action';
import LocationDropdown from './LocationDropdown';
import Menu from './Menu';
import Topbar from './Topbar';

interface IClientHeaderProps {
  className?: { root?: any };
}

const ClientHeader = (props: IClientHeaderProps) => {
  const [open, setOpen] = React.useState(false);
  const [cartOpen, setCartOpen] = React.useState(false);
  const [submenuOpen, setSubmenuOpen] = React.useState(false);
  const [scroll] = useWindowScroll();
  const ref = React.useRef(null);
  const router = useRouter();
  const { cartMemberQuery, cartGuestQuery, deleteCartGuestMutation, deleteCartMemberMutation } = useCart();

  //Check active route
  const pathName = usePathname();
  const toggleMenu = () => {
    setOpen(!open); // Toggle between open and closed
  };
  const toggleCart = () => {
    setCartOpen(!cartOpen);
  };
  const toggleSubmenu = () => setSubmenuOpen(!submenuOpen);

  const activeStyle =
    'py-1 rounded-tl-none rounded-br-none rounded-tr-none rounded-bl-[12px] bg-[linear-gradient(270deg,_rgba(255,_255,_255,_0.00)_0%,_rgba(252,_220,_171,_0.50)_50%)] *:!text-zelene-red !text-zelene-red';

  //For Cart Feature
  const { profile } = useAuth();
  const listCart = profile ? cartMemberQuery?.data?.data : cartGuestQuery?.data?.data;
  const totalServicesCount = listCart?.length;
  const totalAddonsCount = listCart?.reduce((acc: any, item: any) => acc + item?.addons?.length, 0);
  const totalPrice = listCart?.reduce((total: any, service: any) => {
    const servicePrice = parseFloat(service?.service?.price);
    const addonsTotal = service?.addons?.reduce(
      (addonTotal: any, addon: any) => addonTotal + parseFloat(addon?.price),
      0,
    );
    return total + servicePrice + addonsTotal;
  }, 0);

  return (
    <div className='fixed !z-[999] mx-auto w-full bg-zelene-blue' ref={ref}>
      <div className='z-[10] mx-auto flex w-[90%] items-center justify-between whitespace-nowrap'>
        {/* Text Section */}
        <p className='m-0 mx-auto flex items-center gap-2 p-3 text-sm text-white sm:mx-0 sm:p-0'>
          As Seen On{' '}
          <span>
            <a
              href='https://www.nbcdfw.com/texas-today/elevate-your-scalp-health-at-hera-head-spa/3578382/?amp=1'
              target='_blank'
              className='text-white underline'
            >
              NBC
            </a>
          </span>{' '}
          &{' '}
          <span>
            <a
              href='https://www.dmagazine.com/publications/d-magazine/2024/march/the-90-minute-scalp-massage-you-never-knew-you-needed/'
              target='_blank'
              className='text-white underline'
            >
              DMagazine
            </a>
          </span>
        </p>

        <div className='hidden sm:block'>
          <Topbar />{' '}
        </div>
      </div>

      <div
        className={clsx(
          'fixed !z-[999] flex w-full flex-col justify-center gap-4 bg-opacity-50 bg-[linear-gradient(90deg,_#FFFDF9_0%,_#FEE6BE_100%)] py-3 shadow-none transition-all sm:bg-none',
          scroll.y > 0 && '!shadow-2xl transition-all sm:!bg-[linear-gradient(90deg,_#FFFDF9_0%,_#FEE6BE_100%)]',
          props.className?.root,
        )}
      >
        <div className='mx-auto w-[100%] lg:w-[90%]'>
          <div className='mx-auto flex w-[90%] justify-between sm:w-[100%]'>
            <div className='flex items-center justify-between gap-6'>
              <Image
                src={LogoHeader}
                alt='form logo'
                width={84}
                height={91}
                className='h-[60px] w-[56px] cursor-pointer object-contain lg:h-[91px] lg:w-[84px]'
                onClick={() => router.push('/')}
              />

              {/* <div className='flex cursor-pointer flex-col gap-1 rounded-2xl border border-zelene-yellow px-3 py-1'>
                <div className='flex items-center justify-between font-hneu text-zelene-grey *:text-sm'>
                  <span>Current location</span>
                  <DownOutlined />
                </div>

                <div className='flex items-start gap-2 font-hneu font-semibold text-zelene-blue *:text-base'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='20'
                    height='20'
                    viewBox='0 0 24 24'
                    fill='none'
                    stroke='currentColor'
                    stroke-width='2'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                  >
                    <path d='M18 8c0 3.613-3.869 7.429-5.393 8.795a1 1 0 0 1-1.214 0C9.87 15.429 6 11.613 6 8a6 6 0 0 1 12 0' />
                    <circle cx='12' cy='8' r='2' />
                    <path d='M8.714 14h-3.71a1 1 0 0 0-.948.683l-2.004 6A1 1 0 0 0 3 22h18a1 1 0 0 0 .948-1.316l-2-6a1 1 0 0 0-.949-.684h-3.712' />
                  </svg>

                  <div>Frisco, TX</div>
                </div>
              </div> */}

              <LocationDropdown
                className={{
                  root: '!bg-transparent sm:!hidden',
                  text: '*:!text-zelene-blue',
                }}
                fillIcon='#fff4e4'
              />
            </div>

            {/* Navbar */}
            {/*  */}
            <div className='hidden !items-center !justify-center gap-4 *:text-[18px] *:font-medium *:leading-[24.42px] *:text-[#102647] lg:flex'>
              {/* <Link
                href={'/'}
                className={clsx(
                  'flex items-center gap-1.5 px-3 hover:!text-zelene-red',
                  pathName === '/' && activeStyle,
                )}
              >
                
                <span className='!text-zele-red'>Spa</span>
                <Image alt='star footer' width={16} height={16} src={yellowStar} className='h-[16px] w-[16px]' />
              </Link> */}

              <Link
                className={clsx(
                  'flex items-center gap-1.5 px-3 hover:!text-zelene-red',
                  pathName === '/about' && activeStyle,
                )}
                href={'/about'}
              >
                <span className='!text-zele-red hover:text-zelene-red'>About Us</span>
                <Image alt='star footer' width={16} height={16} src={yellowStar} className='h-[16px] w-[16px]' />
              </Link>

              <Link
                href={'/booking'}
                className={clsx(
                  'flex items-center gap-1.5 px-3 hover:!text-zelene-red',
                  pathName === '/booking' && activeStyle,
                )}
              >
                <span className='!text-zele-red'>Booking</span>
                <Image alt='star footer' width={16} height={16} src={yellowStar} className='h-[16px] w-[16px]' />
              </Link>

              <Link className={clsx('px-3', pathName === '/gift' && activeStyle)} href={'/gift'}>
                <span className='!text-zele-red hover:text-zelene-red'>Gift Card</span>
              </Link>

              <Link className={clsx('px-3', pathName === '/services' && activeStyle)} href={'/services'}>
                <span className='!text-zele-red hover:text-zelene-red'>Services</span>
              </Link>

              {/* Locations with Submenu */}
              <div className='relative'>
                <button
                  className={clsx(
                    'flex items-center gap-1 px-3 hover:text-zelene-red',
                    pathName === '/locations' && activeStyle,
                  )}
                  onClick={toggleSubmenu}
                >
                  <span className='!text-zele-red'>Locations</span>
                </button>

                {/* Submenu */}
                {submenuOpen && (
                  <div className='absolute left-0 mt-2 w-60 rounded-md bg-white shadow-lg'>
                    <ul className='my-1 py-2 text-[16px]'>
                      <li>
                        <Link
                        onClick={() => setSubmenuOpen(false)}
                          href='/locations/#mansfield'
                          className='block px-4 py-2 text-gray-700 hover:bg-gray-100 hover:text-zelene-red'
                        >
                          Mansfield | Zelene / Hera Head Spa
                        </Link>
                      </li>
                      <li>
                        <Link
                        onClick={() => setSubmenuOpen(false)}
                          href='/locations#frisco'
                          className='block px-4 py-2 text-gray-700 hover:bg-gray-100 hover:text-zelene-red'
                        >
                          Frisco | Zelene Head Spa
                        </Link>
                      </li>
                      <li>
                        <Link
                        onClick={() => setSubmenuOpen(false)}
                          href='/locations#flower-mound'
                          className='block px-4 py-2 text-gray-700 hover:bg-gray-100 hover:text-zelene-red'
                        >
                          Flower Mound | Zelene / Hera Head Spa
                        </Link>
                      </li>
                    </ul>
                  </div>
                )}
              </div>

              <Link className={clsx('px-3', pathName === '/blog' && activeStyle)} href={'/blog'}>
                <span className='!text-zele-red hover:text-zelene-red'>Blog</span>
              </Link>

              <Link className={clsx('px-3', pathName === '/contact' && activeStyle)} href={'/contact'}>
                <span className='!text-zele-red hover:text-zelene-red'>Contact</span>
              </Link>

              <Link className={clsx('px-3', pathName === '/career' && activeStyle)} href={'/career'}>
                <span className='!text-zele-red hover:text-zelene-red'>Career</span>
              </Link>
            </div>
            {/* Auth Button / Action Button */}
            <div className='flex items-center gap-4 *:cursor-pointer'>
              <HomeAuthAction />
              <Image
                onClick={toggleCart}
                src={Cart}
                alt='cart icon'
                width={24}
                height={24}
                className='size-7 object-contain'
              />
              <MenuFoldOutlined className='block text-[18px] lg:!hidden' onClick={toggleMenu} />
            </div>

            <Drawer
              width={520}
              footer={
                listCart?.length > 0 ? (
                  <div className='flex justify-between'>
                    <div>
                      <div className='text-base font-medium leading-[18px] text-zelene-dark-blue'>
                        Total:&nbsp;
                        <span className='font-hneu text-[18px] font-bold text-zelene-red'>$ {totalPrice} </span>
                      </div>

                      <span className='text-sm font-medium leading-[18px] text-zelene-dark-blue sm:text-base'>
                        ({totalServicesCount} Services - {totalAddonsCount} Add ons)
                      </span>
                    </div>

                    <Link
                      href='/booking#top'
                      className={clsx(
                        'inline-flex items-center justify-center', // Makes it look like a button
                        'rounded-full bg-zelene-yellow px-2 font-hneu text-lg text-white sm:px-12 sm:py-2',
                        'transition-colors duration-300 ease-in-out hover:bg-yellow-500 hover:text-zelene-blue', // Optional hover state
                      )}
                      onClick={() => setCartOpen(false)}
                    >
                      <span className='mr-2'>Book Now</span>
                      {/* <Image alt="star footer" width={16} height={16} src={yellowStar} className="h-[16px] w-[16px]" /> */}
                    </Link>
                  </div>
                ) : (
                  <Link
                    href='/booking#top'
                    className={clsx(
                      'inline-flex w-full items-center justify-center !self-end', // Makes it look like a button
                      'rounded-full bg-zelene-yellow px-12 py-2 font-hneu text-lg text-white',
                      'transition-colors duration-300 ease-in-out hover:bg-yellow-500 hover:text-zelene-blue', // Optional hover state
                    )}
                    onClick={() => setCartOpen(false)}
                  >
                    <span className='mr-2'>Book Now</span>
                  </Link>
                )
              }
              title='Your cart'
              open={cartOpen}
              onClose={() => setCartOpen(false)}
            >
              {listCart?.length > 0 ? (
                <CartList
                  listCart={listCart}
                  onRemove={profile ? deleteCartMemberMutation.mutate : deleteCartGuestMutation.mutate}
                />
              ) : (
                <div className='flex h-full flex-col items-center justify-center font-hneu text-[32px] font-medium text-zelene-grey'>
                  Cart is empty !
                  <div className='relative h-[200px] w-full'>
                    <Image
                      alt='empty cart'
                      fill
                      sizes='(max-width: 640px) 80vw,20vw'
                      className='object-contain'
                      src={EmptyCart}
                    />
                  </div>
                </div>
              )}
            </Drawer>
            {/* Menu control */}
            <Drawer
              styles={{
                body: { backgroundColor: '#FCDCAB41', padding: 0, zIndex: 50, paddingTop: 64 },
                header: {
                  backgroundColor: '#FCDCAB41',
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'center',
                  paddingTop: 17,
                },
              }}
              title={
                <div className='my-auto !flex min-h-10 flex-1 items-center !justify-end gap-2 pr-4'>
                  {/* <SearchOutlined onClick={() => setInputHidden(prev => !prev)} className='cursor-pointer text-[18px]' />
                <Input size='large' className='max-w-[200px]' hidden={inputHidden} placeholder='Search...' /> */}
                  <HomeAuthAction />
                </div>
              }
              width={'100vw'}
              onClose={() => setOpen(false)}
              open={open}
              placement='left'
            >
              <Menu setOpen={setOpen} />
            </Drawer>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientHeader;
