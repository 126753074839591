import { create } from 'zustand';

// Định nghĩa kiểu dữ liệu cho store
interface LocationStore {
  location: { name: string; id: string } | null; // Lưu một location với key-value
  setLocation: (name: string, id: string) => void; // Hàm cập nhật location
}

// Tạo Zustand store
const useLocationStore = create<LocationStore>(set => ({
  location: null, // Khởi tạo giá trị ban đầu
  setLocation: (name, id) => set({ location: { name, id } }), // Ghi đè location với key-value
}));

export default useLocationStore;
