'use client';

import { useRouter } from '@/hooks/useRouter';
import { scrollToTop } from '@/utils/srollToTop';
import clsx from 'clsx';
import Image from 'next/image';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import BGText from '../../../../public/images/Background element_text.png';
import Pattern from '../../../../public/images/Pattern.png';
import WhiteStar from '../../../../public/images/WhiteStar.png';
import yellowStar from '../../../../public/images/yellowStar.png';
import SocialMedia from '../HomePage/HeroSection/SocialMedia';

const activeStyle =
  'py-1 rounded-tl-none rounded-br-none rounded-tr-none rounded-bl-[12px] bg-[linear-gradient(270deg,_rgba(255,_255,_255,_0.00)_0%,_rgba(252,_220,_171,_0.50)_50%)] *:!text-zelene-red !text-zelene-red';

const Menu = (props: any) => {
  const { setOpen } = props;
  const router = useRouter();
  const pathName = usePathname();

  return (
    <div className='reltive'>
      <Image
        src={BGText}
        alt='BGText icon'
        width={400}
        height={110}
        className='absolute left-0 top-[40%] -z-[70] h-[110px] w-full sm:object-cover'
      />

      <Image
        src={WhiteStar}
        alt='BGText icon'
        width={50}
        height={50}
        className='absolute left-4 top-[340px] -z-[70] size-[50px] object-contain'
      />

      <Image
        src={WhiteStar}
        alt='BGText icon'
        width={50}
        height={50}
        className='absolute right-12 top-64 -z-[70] size-[50px] object-contain'
      />

      <Image
        src={WhiteStar}
        alt='BGText icon'
        width={50}
        height={50}
        className='absolute right-20 top-36 -z-[70] size-[50px] object-contain'
      />

      <div className='!z-50 flex flex-col items-center gap-6 pt-4 *:text-[18px] *:italic *:text-[#3C5984]'>
        <Image src={Pattern} alt='Pattern icon' width={90} height={90} className='size-[90px] object-contain' />

        <div className='flex items-center justify-center gap-7'>
          <Link
            onClick={() => {
              router.push('/');
              scrollToTop();
              setOpen(false);
            }}
            href={'/'}
            className={clsx(
              'flex items-center gap-3 text-[#3C5984] hover:text-[#85312b]',
              pathName === '/' && activeStyle,
            )}
          >
            <Image
              src={yellowStar}
              alt='yellowStar icon'
              width={28}
              height={28}
              className='size-[28px] object-contain'
            />
            <span>Spa</span>
          </Link>
          <Link
            onClick={() => {
              router.push('/booking');
              scrollToTop();
              setOpen(false);
            }}
            href={'/booking'}
            className={clsx(
              'flex items-center gap-3 text-[#3C5984] hover:text-[#85312b]',
              pathName === '/booking' && activeStyle,
            )}
          >
            <Image
              src={yellowStar}
              alt='yellowStar icon'
              width={28}
              height={28}
              className='size-[28px] object-contain'
            />

            <span className='text-[#3C5984] hover:text-[#69b1ff]'>Booking</span>
          </Link>
        </div>
        <Link
          onClick={() => {
            router.push('/about');
            scrollToTop();
            setOpen(false);
          }}
          href={'/about'}
          className={clsx(
            'flex items-center gap-3 text-[#3C5984] hover:text-[#85312b]',
            pathName === '/about' && activeStyle,
          )}
        >
          About Us
        </Link>
        <Link
          onClick={() => {
            router.push('/locations');
            scrollToTop();
            setOpen(false);
          }}
          href={'/locations'}
          className={clsx(
            'flex items-center gap-3 text-[#3C5984] hover:text-[#85312b]',
            pathName === '/locations' && activeStyle,
          )}
        >
          Locations
        </Link>
        <Link
          onClick={() => {
            router.push('/services');
            scrollToTop();
            setOpen(false);
          }}
          href={'/services'}
          className={clsx(
            'flex items-center gap-3 text-[#3C5984] hover:text-[#85312b]',
            pathName === '/services' && activeStyle,
          )}
        >
          Services
        </Link>
        <Link
          onClick={() => {
            router.push('/blog');
            scrollToTop();
            setOpen(false);
          }}
          href={'/blog'}
          className={clsx(
            'flex min-w-[70px] items-center justify-center gap-3 text-[#3C5984] hover:text-[#85312b]',
            pathName === '/blog' && activeStyle,
          )}
        >
          Blog
        </Link>
        <Link
          onClick={() => {
            router.push('/contact');
            scrollToTop();
            setOpen(false);
          }}
          href={'/contact'}
          className={clsx(
            'flex items-center gap-3 text-[#3C5984] hover:text-[#85312b]',
            pathName === '/contact' && activeStyle,
          )}
        >
          Contact
        </Link>
        <Link
          onClick={() => {
            router.push('/career');
            scrollToTop();
            setOpen(false);
          }}
          href={'/career'}
          className={clsx(
            'flex items-center gap-3 text-[#3C5984] hover:text-[#85312b]',
            pathName === '/career' && activeStyle,
          )}
        >
          Career
        </Link>

        <Link
          onClick={() => {
            router.push('/gift');
            scrollToTop();
            setOpen(false);
          }}
          href={'/gift'}
          className={clsx(
            'flex items-center gap-3 text-[#3C5984] hover:text-[#85312b]',
            pathName === '/gift' && activeStyle,
          )}
        >
          Gift Card
        </Link>
      </div>

      <SocialMedia
        className={{
          root: 'mx-auto mt-10 !flex w-fit !flex-row !px-5 !py-2',
          placement: 'bottom',
        }}
      />
    </div>
  );
};

export default Menu;
